import { createRouter, createWebHistory } from "vue-router";
import { env } from "dingtalk-javascript-utility";

import routes from "./routes";
import { ActionTypes as dingtalkActionTypes } from "@/store/modules/dingtalk";
import { isAuthenticated } from "@/utils/auth";

import store from "@/store";
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return {
      el: "#app",
      top: 0,
      behavior: "smooth",
    };
  },
});

router.beforeEach(async (to, from, next) => {
  document.title = `盛元智本项目管理系统--${to.meta.title}`;
  if (env.isDingTalk) {
    const corpId = process.env.VUE_APP_DINGTALK_CORPID;

    if (!isAuthenticated()) {
      // 钉钉免登
      await store.dispatch(dingtalkActionTypes.LOGIN(), { corpId });
    }
    next();
  } else {
    // if (to.name!=='login' && !isAuthenticated()) {
    //   next({
    //     name: 'login',
    //   });
    // } else {
    next();
    // }
  }
});

router.afterEach(async (to, from) => {
  if (env.isDingTalk) {
    const thisUrl = window.location.href.split("#")[0];
    // 钉钉JSAPI鉴权
    const corpId = process.env.VUE_APP_DINGTALK_CORPID;
    const agentId = process.env.VUE_APP_DINGTALK_AGENTID;
    await store.dispatch(dingtalkActionTypes.JSAPI_SINGNATURE(), {
      corpId,
      agentId,
      url: thisUrl,
      jsApiList: [
        "runtime.permission.requestOperateAuthCode", //获取微应用反馈式操作的临时授权码
        "biz.ding.create", //DING 2.0 发钉
        "biz.contact.choose", //PC端选择企业内部的人
        "biz.contact.complexPicker", //选择部门和人
        "biz.contact.departmentsPicker", //选择部门
        "biz.customContact.choose", //单选自定义联系人
        "biz.customContact.multipleChoose", //多选自定义联系人
        "biz.cspace.preview", //预览钉盘文件
        "biz.util.uploadAttachment", //上传附件到钉盘/从钉盘选择文件
      ],
    });
  }
});

export default router;
