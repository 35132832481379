import { JsApiAuthentication, GetCustomSpaceId, GrantCustomSpaceId, GetDingDriveSpacesFiles } from '@/api/dingtalk'
import { login } from '@/api/account';

import * as dd from 'dingtalk-jsapi';
import { MutationTypes as accountMutationTypes } from '@/store/modules/account';

export const ActionTypes = {
  JSAPI_SINGNATURE: (root = true) => `${root ? 'dingtalk/' : ''}jsapi_singnature`,
  LOGIN: (root = true) => `${root ? 'dingtalk/' : ''}login`,
  // 授权
  GRANT: (root = true) => `${root ? 'dingtalk/' : ''}grant`,
  // 获取钉盘空间文件
  TOOLKIT_FILES: (root = true) => `${root ? 'dingtalk/' : ''}toolkit_files`,
}

// export const MutationTypes = {

// }
const findFolder = (items, fileId, files) => {
  return items.map(item => {
    if (item.isLeaf === false) {
      if (item.key === fileId) {
        item.children = files;
      } else {
        item.children = findFolder(item.children || [], fileId, files);
      }
    }
    return item;
  })
}

const dingtalk = {
  namespaced: true,
  state: () => ({
    toolkit: []
  }),
  getters: {

  },
  mutations: {

    toolkit(state, { parentId = 0, files = [] }) {
      if (parentId === 0 || parentId === undefined || parentId === null) {
        state.toolkit = files;
      } else {
        state.toolkit = findFolder(state.toolkit, parentId, files);
      }
    }
  },
  actions: {
    [ActionTypes.LOGIN(false)]({ commit }, payload) {
      const { corpId } = payload;

      return new Promise((resolve, reject) => {
        dd.ready(function () {
          dd.runtime.permission.requestAuthCode({
            corpId: corpId,
            onSuccess: function (result) {
              login(result.code)
                .then(res => {
                  // alert(JSON.stringify(res));
                  commit(accountMutationTypes.SET_TOKEN(), res.data, { root: true });
                  resolve(res);
                })
                .catch(err => {
                  reject(err);
                });
            },
            onFail: function (err) {
              reject(err);
            }
          });
        });
        dd.error(function (error) {
          reject(error);
        });
      });
    },
    [ActionTypes.JSAPI_SINGNATURE(false)](_context, payload) {
      const { url, agentId, corpId, jsApiList } = payload;
      return new Promise((resolve, reject) => {
        JsApiAuthentication(url)
          .then(res => {
            dd.config(Object.assign({}, res.data, {
              agentId: agentId,
              corpId: corpId,
              type: 0,
              jsApiList: jsApiList
            }));
            resolve(res);
          })
          .catch(err => {
            // alert('鉴权异常:'+JSON.stringify(err));
            reject(err);
          });

        dd.error(function (error) {
          // alert("dd error: " + JSON.stringify(error));
          if (error.errorCode !== "9" && error.errorCode !== "-1") {
            reject(error);
          }
        });
      });
    },
    async [ActionTypes.GRANT(false)](context, payload) {
      const { type, spaceId, fileids } = payload;
      await GrantCustomSpaceId(type, spaceId, fileids);
    },
    async [ActionTypes.TOOLKIT_FILES(false)]({ commit }, payload) {
      const { parentId, token } = payload;
      const response = await GetDingDriveSpacesFiles(parentId, token);
      commit('toolkit', {
        parentId, files: (response.data.files || []).map(item => {
          return {
            title: item.fileName,
            key: item.fileId,
            isLeaf: item.fileType === 'file',
            selectable: item.fileType === 'file',
            spaceId: item.spaceId,
            fileId: item.fileId,
            fileName: item.fileName,
            fileSize: item.fileSize,
            fileType: item.fileExtension,
          }
        })
      });
    }
  }
}


export default dingtalk