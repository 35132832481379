import requests from '@/utils/axios';
import * as api from './api';

export const GetApprovalList = () => { 
  return requests.get(api.GetApprovalListApi);
}

/**
 * 获取审批架构
 * @param { {itemId:0, taskId:0, type:1|2|3|4|5} } query 
 * @returns 
 */
export const GetProcessSchema = (query) => {
  return requests.get(api.GetProcessSchemaApi, {
    params: query,
  });
};

/**
 * 发起审批
 * @param {Object} data 
 * @returns 
 */
export const CreateProcess = (data) => { 
  return requests.post(api.CreateProcessApi, data);
}


/**
 * 获取审批角色
 * @returns 
 */
export const GetProcessRoles = () => { 
  return requests.get(api.GetProcessRolesApi);
}

/**
 * 获取审批成功后执行工作
 * @returns 
 */
export const GetProcessCompletedJobs = () => {
  return requests.get(api.GetProcessCompletedJobsApi);
};