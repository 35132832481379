import * as projectService from '@/api/project';

const namespace = 'dynamic';

const state = {
  project: [],
  task: []
};

export const GetterTypes = {
  LIST: (root = true) => `${root ? namespace + '/' : ''}list`,
}
const getters = {
  [GetterTypes.LIST(false)]: (state) => (projectId, taskId) => {
    if (taskId) {
      return state.task.filter(item => item.projectId === projectId && item.taskId === taskId);
    } else {
      return state.project.filter(item => item.projectId === projectId);
    }
  }
};


const mutations = {
  update_project(state, payload) {
    state.project = payload;
  },
  update_task(state, payload) { 
    state.task = payload;
  }
};

export const ActionTypes = {
  LIST: (root = true) => `${root ? namespace + '/' : ''}list`,
};

const actions = {
  async [ActionTypes.LIST(false)]({ commit, getters, rootGetters, state }, payload) {
    const { projectId, taskId } = payload;
    const response = await projectService.GetProjectDynamics(projectId, taskId);
    const data = response.data.map(item => {
      return Object.assign({}, item, {
        dynamicId: item.itemDynamicId,
        projectId
      });
    });
    if (taskId) {
      commit('update_task', data);
    } else { 
      commit('update_project', data);
    }
    
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};