import * as projectService from '@/api/project';
import { GetterTypes as AccountGetterTypes } from './account';
import { ActionTypes as dynamicActionTypes } from './dynamic'
import RoleTypes from "@/utils/roles";

const namespace = 'project';

export const ProjectStatus = {
  /**
   * 已删除
   */
  Deleted: 0,
  /**
   * 进行中
   */
  Processing: 1,
  /**
   * 中止
   */
  Suspend: 11,
  /**
   * 终止
   */
  Finished: 21,
  /**
   * 归档
   */
  Archived: 31
}

const format = (item) => {
  const data = Object.assign({}, item, {
    leader: item.pmEmployee
      ? {
          name: item.pmEmployee.employeeName,
          employeeId: item.pmEmployee.employeeId,
        }
      : null,
    creator: item.creatorEmployee
      ? {
          name: item.creatorEmployee.employeeName,
          employeeId: item.creatorEmployee.employeeId,
        }
      : null,
    caiwu: item.caiWuEmployee
      ? {
          name: item.caiWuEmployee.employeeName,
          employeeId: item.caiWuEmployee.employeeId,
        }
      : null,
    fengkong: item.fengKongEmployee
      ? {
          name: item.fengKongEmployee.employeeName,
          employeeId: item.fengKongEmployee.employeeId,
        }
      : null,
    zonghe: item.zongHeEmployee
      ? {
          name: item.zongHeEmployee.employeeName,
          employeeId: item.zongHeEmployee.employeeId,
        }
      : null,
    projectId: item.itemId,
    members: (item.employees || []).map((i) => ({
      name: i.employeeName,
      employeeId: i.employeeId,
    })),
    classifyId: item.itemClassifyId,
    typeId: item.itemTypeId,
  });
  delete data.creatorEmployee;
  delete data.pmEmployee;
  delete data.caiWuEmployee;
  delete data.fengKongEmployee;
  delete data.zongHeEmployee;
  delete data.itemId;
  delete data.employees;
  delete data.remindEmployees;
  delete data.itemClassifyId;
  delete data.itemTypeId;
  return data;
}

export const ActionTypes = {
  LIST: (root = true) => `${root ? namespace + "/" : ""}jsapi_singnature`,
  OVERVIEW: (root = true) => `${root ? namespace + "/" : ""}overview`,
  CLASSIFIES: (root = true) => `${root ? namespace + "/" : ""}classifies`,
  TYPES: (root = true) => `${root ? namespace + "/" : ""}types`,
  DYNAMICS: (root = true) => `${root ? namespace + "/" : ""}dynamics`,
  ADD: (root = true) => `${root ? namespace + "/" : ""}add`,
  UPDATE: (root = true) => `${root ? namespace + "/" : ""}update`,
  DELETE_MEMBER: (root = true) => `${root ? namespace + "/" : ""}delete_member`,
};

export const GetterTypes = {
  FIND: (root = true) => `${root ? namespace + '/' : ''}find`,
}

export const Mutations = {
  UPDATE: (root = true) => `${root ? namespace + '/' : ''}update_project`,
}

// export const Roles = {
//   Leader: "leader",
//   FengKong: "fengkong",
//   CaiWu: "caiwu",
//   ZongHe: "zonghe",
//   Member: "member",
//   Admin: "admin",
//   Senior: "senior",
//   Creator:"creator"
// };

const project = {
  namespaced: true,
  state: () => ({
    list: [],
    classifies: [],
    types: [],
  }),
  getters: {
    [GetterTypes.FIND(false)]:
      (state, getters, rootState, rootGetters) => (id) => {
        // console.log('find', rootState, rootGetters[AccountGetterTypes.GET_EMPLOYEE_ID()])
        const employeeId = rootGetters[AccountGetterTypes.GET_EMPLOYEE_ID()];
        const proj = state.list.find((item) => item.projectId === id) || {};
        const roles = [];
        if (proj.leader && proj.leader.employeeId === employeeId) {
          roles.push(RoleTypes.Leader);
        }
        if (proj.creator && proj.creator.employeeId === employeeId) {
          roles.push(RoleTypes.Creator);
        }
        if (proj.fengkong && proj.fengkong.employeeId === employeeId) {
          roles.push(RoleTypes.FengKong);
        }
        if (proj.caiwu && proj.caiwu.employeeId === employeeId) {
          roles.push(RoleTypes.CaiWu);
        }
        if (proj.zonghe && proj.zonghe.employeeId === employeeId) {
          roles.push(RoleTypes.ZongHe);
        }
        if (
          proj.members &&
          proj.members.some((item) => item.employeeId === employeeId)
        ) {
          roles.push(RoleTypes.Member);
        }
        const isAdmin = rootGetters[AccountGetterTypes.IS_ADMIN()] || false;
        if (isAdmin) {
          roles.push(RoleTypes.Admin);
        }

        const isSenior = rootGetters[AccountGetterTypes.IS_SENIOR()] || false;
        if (isSenior) {
          roles.push(RoleTypes.Senior);
        }

        return Object.assign({}, proj, {
          projectId: id,
          roles,
          inRole: (...rs) => {
            return rs.some((role) => roles.includes(role));
          },
        });
      },
  },
  mutations: {
    [Mutations.UPDATE(false)](state, payload) {
      // 是否存在
      const isExist = state.list.some(
        (item) => item.projectId === payload.projectId
      );
      if (isExist) {
        // 更新
        state.list = state.list.map((item) => {
          if (item.projectId === payload.projectId) {
            Object.assign(item, payload);
          }
          return item;
        });
      } else {
        // 新增
        state.list.push(payload);
      }
    },
    update_classifies(state, payload) {
      state.classifies = payload.map((item) =>
        Object.assign({}, item, {
          text: item.text,
          value: parseInt(item.value),
        })
      );
    },
    update_types(state, payload) {
      state.types = payload.map((item) => ({
        text: item.text,
        value: parseInt(item.value),
      }));
    },
    delete_member(state, payload) { 
      const { projectId, index } = payload;
      state.list.forEach((item) => {
        if (item.projectId === projectId) {
          item.members.splice(index, 1);
        }
      });
    }
  },
  actions: {
    async [ActionTypes.LIST(false)]({ commit }, payload) {
      const response = await projectService.GetProjectList(payload);
      const { data } = response;
      const projects = data.data.map((item) => {
        const project = Object.assign({}, item, {
          projectId: item.itemId,
          leader: item.pm,
          tasks: {
            total: item.taskTotalCount,
            done: item.taskFinishCount,
          },
          type: item.itemClassifyName || "-",
          progress:
            item.taskTotalCount === 0
              ? 0
              : Math.floor((item.taskFinishCount / item.taskTotalCount) * 100),
        });

        commit(Mutations.UPDATE(false), project);
        return project;
      });
      return Object.assign({}, data, {
        data: projects,
      });
    },
    async [ActionTypes.OVERVIEW(false)]({ commit }, payload) {
      const response = await projectService.GetProjectOverview(payload);
      // 待更新
      const overview = format(response.data);
      commit(Mutations.UPDATE(false), overview);
      return overview;
    },
    async [ActionTypes.CLASSIFIES(false)]({ commit, state }) {
      if (state.classifies.length === 0) {
        const response = await projectService.GetProjectClassifies();
        const classifies = response.data;
        commit("update_classifies", classifies);
        return classifies;
      }
      return state.classifies;
    },
    async [ActionTypes.TYPES(false)]({ commit, state }) {
      if (state.types.length === 0) {
        const response = await projectService.GetProjectTypes();
        const types = response.data;
        commit("update_types", types);
        return types;
      }
      return state.types;
    },
    async [ActionTypes.DYNAMICS(false)]({ commit }, payload) {
      const { projectId, taskId } = payload;
      const response = await projectService.GetProjectDynamics(
        projectId,
        taskId
      );
      return response.data.map((item) => {
        return Object.assign({}, item, {
          dynamicId: item.itemDynamicId,
        });
      });
    },
    async [ActionTypes.ADD(false)]({ commit }, payload) {
      const response = await projectService.AddProject(payload);
      const { itemId } = response.data;
      commit(
        Mutations.UPDATE(false),
        Object.assign({}, payload, { projectId: itemId })
      );
      return itemId;
    },
    async [ActionTypes.UPDATE(false)]({ commit, dispatch }, payload) {
      const { projectId } = payload;
      await projectService.UpdateProject(projectId, payload);
      commit(Mutations.UPDATE(false), payload);
      dispatch(dynamicActionTypes.LIST(), payload, { root: true });
    },
    async [ActionTypes.DELETE_MEMBER(false)]({ commit}, payload) {
      const { projectId, employeeId } = payload;
      await projectService.DeleteProjectMember(projectId, employeeId);
      commit("delete_member", payload);
    },
  },
};


export default project