import * as base from './base';
import * as project from './project';
import * as task from './task';
import * as template from './template';

export default {
  base,
  project,
  task,
  template
}