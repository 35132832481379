import qs from 'qs'

import requests from '@/utils/axios';
import { LoginApi, GetDashboardAnalysisApi, GetDepartmentApi } from "./api";


export const login = (code) => {
  var data = qs.stringify({
    'client_id': 'dingtalk',
    'client_secret': 'secret',
    'grant_type': 'dingtalk_code',
    'code': code,
    'scopes': ["crm", "dingtalk", "hr", "openid", "file", "processes", "investorcrm", "finance", "item"]
  });
  return requests.post(LoginApi, data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  });
};

/**
 * 获取分析报表数据
 * @returns 
 */
export const GetDashboardAnalysis = () => { 
  return requests.get(GetDashboardAnalysisApi);
}

/**
 * 获取部门列表
 * @returns 
 */
export const GetDepartment = () => { 
  return requests.get(GetDepartmentApi);
}