import requests from '@/utils/axios';
import * as api from './api';
import dayjs from 'dayjs'

export const GetProjectList = (params) => {
  const { page, pageSize, name, createTime, type, leader, member, key } = params;
  let memberStr = member;
  if (Array.isArray(member)) {
    memberStr = member.join(',');
  }
  return requests.get(`${api.ProjectListApi}`, {
    params: params,
  });
}

export const GetProjectClassifies = () => {
  return requests.get(api.ProjectClassifiesApi, { hideLoading: true });
}

export const GetProjectTypes = () => {
  return requests.get(api.ProjectTypesApi, { hideLoading: true });
}

// 概览
export const GetProjectOverview = (id) => {
  return requests.get(api.ProjectOverviewApi.replace('{_id}', id));
}

export const GetProjectDynamics = (projectId, taskId) => {
  const uri = taskId ? api.GetProjectDynamicsApi(projectId) + `?taskId=${taskId}` : api.GetProjectDynamicsApi(projectId);
  return requests.get(uri);
}

export const AddProject = (params) => {
  return requests.post(api.AddProjectApi, params);
}

export const UpdateProject = async (projectId, params) => {
  var keys = Object.keys(params);
  for (var i = 0; i < keys.length; i++) {
    const key = keys[i];
    switch (key) {
      case 'cover':
        return await requests.put(api.UpdateProjectCover(projectId), params);
      case 'name':
        return await requests.put(api.UpdateProjectName(projectId), params);
      case 'classifyId':
        return await requests.put(api.UpdateProjectClassify(projectId), { itemClassifyId: params[key] });
      case 'typeId':
        return await requests.put(api.UpdateProjectType(projectId), { itemTypeId: params[key] });
      case 'description':
        return await requests.put(api.UpdateProjectDescription(projectId), params);
      case 'startTime':
        return await requests.put(api.UpdateProjectTime(projectId), params);
      case 'status':
        return await requests.put(api.UpdateProjectStatus(projectId), params);
      case 'fengkong':
        return await requests.put(api.UpdateProjectFengkong(projectId), {
          fengKongEmployeeId: params[key].employeeId
        });
      case 'caiwu':
        return await requests.put(api.UpdateProjectCaiwu(projectId), {
          caiWuEmployeeId: params[key].employeeId
        });
      case 'zonghe':
        return await requests.put(api.UpdateProjectZonghe(projectId), {
          zongHeEmployeeId: params[key].employeeId
        });
      default:
        break;

    }
  }
  // return new Promise((resolve, reject) => {
  //   let response;
  //   Object.keys(params).some(async key => {
  //     switch (key) {
  //       case 'cover':
  //         response = await requests.put(api.UpdateProjectCover(projectId), params);
  //         return true;
  //       default:
  //         return false;
  //     }
  //   });

  //   if (response) {
  //     resolve(response);
  //   } else {
  //     reject();
  //   }
  // });

}

/**
 * 删除项目成员
 * @param {number} projectId 
 * @param {string} employeeId 
 * @returns 
 */
export const DeleteProjectMember = (projectId, employeeId) => { 
  return requests.delete(api.DeleteProjectMemberApi(projectId), {
    data: { employeeId: employeeId },
  });
}

/**
 * 获取项目经理列表
 * @returns 
 */
export const GetPMs = () => { 
  return requests.get(api.GetProjectPMsApi);
}