import Layout from "@/layouts/Layout.vue";

import { ProjectOutlined } from "@ant-design/icons-vue";

export default [
  {
    path: "/project",
    name: "project_base",
    redirect: "/project/list",
    component: Layout,
    meta: {
      title: "项目",
      icon: <ProjectOutlined />,
    },
    children: [
      {
        path: "list",
        name: "project",
        component: () => import("@/views/project/list/IndexView.vue"),
        meta: { title: "项目列表" },
        children: [
          {
            name: "project-create",
            path: "/project/create",
            meta: { title: "创建项目" },
            component: () =>
              import(
                /* webpackChunkName: "project" */ "@/views/project/CreateView.vue"
              ),
          },
        ],
      },
      {
        name: "project-detail",
        path: "/project/detail/:projectId(\\d+)",
        component: () =>
          import(
            /* webpackChunkName: "project" */ "@/views/project/detail/IndexView.vue"
          ),
        props: (route) => { 
          return {
            projectId: parseInt(route.params.projectId)
          };
        },
        children: [
          {
            name: "template_import",
            path: "template/import",
            component: () =>
              import(
                /* webpackChunkName: "project" */ "@/views/template/import/IndexView.vue"
              ),
            props: (route) => ({ projectId: parseInt(route.params.projectId) }),
          },
          {
            name: "task-create",
            path: "task/create",
            component: () =>
              import(
                /* webpackChunkName: "project" */ "@/views/task/CreateView.vue"
              ),
            props: (route) => ({
              projectId: parseInt(route.params.projectId),
              taskType: parseInt(route.query.taskType),
              milestoneId:
                route.query.milestoneId && parseInt(route.query.milestoneId),
            }),
          },
        ],
      },
      {
        name: "task-detail",
        path: "/project/detail/:projectId(\\d+)/task/:taskId(\\d+)",
        component: () =>
          import(
            /* webpackChunkName: "task" */ "@/views/task/detail/IndexView.vue"
          ),
        meta: { title: "任务详情" },
        props: (route) => ({
          projectId: parseInt(route.params.projectId),
          taskId: parseInt(route.params.taskId),
          taskType: parseInt(route.query.taskType),
        }),
      },
    ],
  },
];
