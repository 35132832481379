import requests from '@/utils/axios';
import * as api from './api';


export const GetProjectTags = (projectId) => {
  return requests.get(api.GetProjectTagsApi(projectId));
};

export const AddTag = (data) => { 
  return requests.post(api.AddTagApi, data);
}

export const DeleteTag = (tagId) => { 
  return requests.delete(api.DeleteTagApi(tagId));
}