import * as kpiService from '@/api/kpi';
import { GetterTypes as AccountGetterTypes } from './account.js'
import dayjs from 'dayjs';

const namespace = 'kpi';

const format = (item) => {

  return item;
}

const state = {
  score_list: [],
  list: [],
  members: [],
  result:[]
};

export const GetterTypes = {
  FIND_DAILY_SCORE_LIST_BY_PROJECTID: (root = true) => `${root ? namespace + '/' : ''}find_score_list_by_projectid`,
  FIND_BY_PROJECTID: (root = true) => `${root ? namespace + '/' : ''}find_by_projectid`,
  FIND_MEMBERS_BY_KPIID: (root = true) => `${root ? namespace + '/' : ''}find_members_by_kpiid`,
  FIND_RESULT_BY_KPIID_EMPLEEYID: (root = true) => `${root ? namespace + '/' : ''}find_result_by_kpiid_empleeyid`,
}

const getters = {
  [GetterTypes.FIND_DAILY_SCORE_LIST_BY_PROJECTID(false)]: (state) => (projectId) => {
    return state.score_list.filter(item => item.projectId === projectId) || [];
  },
  [GetterTypes.FIND_BY_PROJECTID(false)]: (state) => (projectId) => {
    return state.list.filter(item => item.projectId === projectId) || [];
  },
  [GetterTypes.FIND_MEMBERS_BY_KPIID(false)]: (state) => (kpiId) => { 
    return state.members.filter(item => item.kpiRecordId === kpiId) || [];
  },
  [GetterTypes.FIND_RESULT_BY_KPIID_EMPLEEYID(false)]: (state) => (kpiId, employeeId) => { 
    return state.result.filter(item => item.kpiRecordId === kpiId && item.employeeId === employeeId) || [];
  }
};


const mutations = {
  update_daily_score(state, payload) {
    state.score_list = payload;
  },
  add_score(state, payload) {
    state.score_list.unshift(payload);
  },
  add(state, payload) {
    state.list.push(payload);
  },
  update(state, payload) { 
    state.list = payload;
  },
  update_members(state, payload) { 
    state.members = payload;
  },
  update_result(state, payload) { 
    state.result = payload;
  },
  update_score(state, payload) { 
    const { kpiItemResultId } = payload;
    const index = state.result.findIndex(x => x.kpiItemResultId === kpiItemResultId);
    if (index !== -1) {
      state.result[index] = Object.assign({}, state.result[index], payload);
    }
  }
};


export const ActionTypes = {
  DAILY_SCORE_LIST: (root = true) => `${root ? namespace + '/' : ''}daily_score_list`,
  ADD_DAILY_SCORE: (root = true) => `${root ? namespace + '/' : ''}add_daily_score`,
  CREATE: (root = true) => `${root ? namespace + '/' : ''}create`,
  LIST: (root = true) => `${root ? namespace + '/' : ''}list`,
  MEMBERS: (root = true) => `${root ? namespace + '/' : ''}members`,
  GET: (root = true) => `${root ? namespace + '/' : ''}get`,
  ADD_SCORE: (root = true) => `${root ? namespace + '/' : ''}add_score`,
}

const actions = {
  async [ActionTypes.DAILY_SCORE_LIST(false)]({ commit }, payload) {
    const { projectId } = payload;
    const response = await kpiService.ScoreList(projectId);
    const data = response.data.map(item => Object.assign({}, item, {
      projectId }));
    commit('update_daily_score', data);
  },
  async [ActionTypes.ADD_DAILY_SCORE(false)]({ commit, rootGetters }, payload) {
    const { projectId } = payload;
    const response = await kpiService.AddDailyScore(projectId, payload);

    commit('add_score', Object.assign({}, payload, response.data, {
      createTime: dayjs().format('YYYY-MM-DD HH:mm'),
      creator: rootGetters[AccountGetterTypes.GET_NAME()]
    }));
  },
  async [ActionTypes.CREATE(false)]({ commit }, payload) {
    const { projectId } = payload;
    const response = await kpiService.Create(projectId, payload);
    const data = Object.assign({}, payload, response.data);

    commit('add', data);
  },
  async [ActionTypes.LIST(false)]({ commit }, payload) {
    const { projectId } = payload;
    const response = await kpiService.List(projectId);
    const data = response.data.map(item => Object.assign({}, item, {
      projectId, employees: item.employees.map(x => ({
        employeeId: x.employeeId,
        name: x.employeeName
      })) }));
    commit('update', data);
  },
  async [ActionTypes.MEMBERS(false)]({ commit }, payload) { 
    const { kpiRecordId, projectId } = payload;
    const response = await kpiService.GetKpiMembers(kpiRecordId);
    const data = response.data.employees.map(item => Object.assign({}, {
      employeeId: item.employee.employeeId,
      name: item.employee.employeeName,
      score: item.totalScore,
      kpiRecordId: kpiRecordId,
      projectId: projectId,
      kpiClassify: response.data.kpiClassify
    }));
    commit('update_members', data);
  },
  async [ActionTypes.GET(false)]({ commit }, payload) { 
    const { kpiRecordId, projectId, employeeId } = payload;
    const response = await kpiService.GetKpiMemberAssessmentDetail(kpiRecordId, employeeId);

    commit('update_result', response.data.map(item => { 
      return Object.assign({}, item, { kpiRecordId, employeeId })
    }));
  },
  async [ActionTypes.ADD_SCORE(false)]({ commit }, payload) { 
    const { kpiItemResultId } = payload;
    const response = await kpiService.AddScore(kpiItemResultId, payload);

    commit('update_score', payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};