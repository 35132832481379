import requests from '@/utils/axios';
import * as api from './api';

export const AddDailyScore = (projectId, params) => {
  return requests.post(api.CreateKpiScoreRecordApi(projectId), params);
}

export const ScoreList = (projectId) => {
  return requests.get(api.GetKpiScoreRecordApi(projectId));
}


export const Create = (projectId, params) => {
  return requests.post(api.CreateKpiAssessmentApi(projectId), params);
}

export const List = (projectId) => {
  return requests.get(api.GetKpiAssessmentApi(projectId));
}

export const GetKpiMembers = (kpiId) => { 
  return requests.get(api.GetKpiMemberAssessmentApi(kpiId));
}

export const GetKpiMemberAssessmentDetail = (kpiId, employeeId) => { 
  return requests.get(`${api.GetKpiMemberAssessmentDetailApi(kpiId)}?employeeId=${employeeId || ''}`);
}

//评分
export const AddScore = (kpiItemId, params) => { 
  return requests.put(api.AddKpiScoreApi(kpiItemId), params);
}