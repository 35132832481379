<template>
  <a-config-provider :locale="zhCN">
    <router-view></router-view>
  </a-config-provider>
</template>
<script setup>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import timezone from 'dayjs/plugin/timezone';
import calendar from 'dayjs/plugin/calendar';
import updateLocale from 'dayjs/plugin/updateLocale';
import relativeTime from 'dayjs/plugin/relativeTime';
var isLeapYear = require('dayjs/plugin/isLeapYear')

dayjs.locale('zh-cn');
dayjs.extend(calendar);
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.extend(isLeapYear);
dayjs.extend(timezone)
dayjs.tz.setDefault("Asia/Shanghai")
dayjs.updateLocale('zh-cn', {
  calendar: {
    sameDay: '[今天] HH:mm', // The same day ( Today at 2:30 AM )
    nextDay: '[明天] HH:mm', // The next day ( Tomorrow at 2:30 AM )
    nextWeek: function (now) {
      const targetWeek = this.day() === 0 ? 7 : this.day();
      const nowWeek = now.day() === 0 ? 7 : now.day();
      if (nowWeek < targetWeek) {
        return this.format('ddd HH:mm');
      } else {
        return `下${this.format('ddd HH:mm')}`;
      }

    }, // The next week ( Sunday at 2:30 AM )
    lastDay: '[昨天] HH:mm', // The day before ( Yesterday at 2:30 AM )
    lastWeek: function (now) {
      const targetWeek = this.day() === 0 ? 7 : this.day();
      const nowWeek = now.day() === 0 ? 7 : now.day();
      if (nowWeek > targetWeek) {
        return this.format('ddd HH:mm');
      } else {
        return `上${this.format('ddd HH:mm')}`;
      }
    }, // Last week ( Last Monday at 2:30 AM )
    sameElse: 'YYYY-MM-DD HH:mm' // Everything else ( 17/10/2011 )
  }
})
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #2c3e50; */
  /* margin-top: 60px; */
}

/* #components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
} */
</style>
