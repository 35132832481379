import requests from '@/utils/axios';
import * as api from './api';

export const GetTaskDeliverableList = (taskId) => {
  return requests.get(api.GetTaskDeliverableApi(taskId));
}

export const UpdateTaskDeliverable = (data) => {
  const { taskId } = data;
  return requests.post(api.UpdateTaskDeliverableApi(taskId), data);
}

export const UploadDeliverable = (data) => {
  const { deliverableId, files } = data;
  const req = {
    taskDeliverableDemandId: deliverableId,
    files
  }
  return requests.post(api.UploadDeliverableApi, req);
}

export const DeleteDeliverableFile = (deliverableFileId) => {
  return requests.delete(api.DeleteDeliverableApi(deliverableFileId));
}