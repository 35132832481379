import requests from '@/utils/axios';
import { JsApiAuthenticationApi, GetCustomSpaceIdApi, GrantCustomSpaceIdApi, GetDingDriveSpacesFilesApi } from './api';

export const JsApiAuthentication = (url) => {
  if (!url) {
    return;
  }
  return requests.get(`${JsApiAuthenticationApi}?url=${encodeURIComponent(url)}`, { hideLoading: true });
};

export const GetCustomSpaceId = (domain) => {
  return requests.get(`${GetCustomSpaceIdApi}?domain=${domain}`);
}

export const GrantCustomSpaceId = (type, spaceId, fileids) => {
  //type:add：上传；download：下载；delete：删除；list：获取列表；preview：预览
  return requests.get(`${GrantCustomSpaceIdApi}?type=${type}&spaceId=${spaceId}&fileids=${fileids||''}`);
}

export const GetDingDriveSpacesFiles = (parentId, token) => {
  return requests.get(`${GetDingDriveSpacesFilesApi}?parentId=${parentId || 0}&nextToken=${token || ''}`);
}