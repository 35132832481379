import project from "@/store/modules/project";

const ApiVersion = process.env.VUE_APP_API_VERSION || 'v1.0';

// 钉钉JSAPI鉴权
export const JsApiAuthenticationApi = `/api/${ApiVersion}/DingTalk/JsApiSingnature`

// 获取钉盘空间Id
export const GetCustomSpaceIdApi = `/api/${ApiVersion}/DingTalk/GetSpaceId`
// 授权空间
export const GrantCustomSpaceIdApi = `/api/${ApiVersion}/DingTalk/GetAuthority`


// 登录
export const LoginApi = `${process.env.VUE_APP_IDENTITY_URL}/connect/token`

// 项目列表
export const ProjectListApi = `/api/Item`

// 项目分类
export const ProjectClassifiesApi = '/api/Item/Classify/Options'

// 项目类型
export const ProjectTypesApi = '/api/Item/Type/Options'

// 项目概览
export const ProjectOverviewApi = `/api/Item/{_id}`

// 项目任务列表
export const ProjectTaskListApi = `/api/Item/{_id}/Tasks`

// 添加项目任务
export const AddProjectTaskApi = `/api/Task`

export const GetMyTasksApi = `/api/Task`

export const TaskDetailApi= `/api/Task/{_id}`

// 项目标签列表
export const GetProjectTagsApi = (projectId) => `/api/Tag/${projectId}`
// 添加标签
export const AddTagApi = `/api/Tag`;
// 删除标签
export const DeleteTagApi = (tagId) => `/api/Tag/${tagId}`;

// 审批列表
export const GetApprovalListApi = '/api/Process';

// 项目或任务动态
export const GetProjectDynamicsApi = (projectId) => `/api/Item/${projectId}/Dynamics`;

// 修改任务名称
export const UpdateTaskNameApi = (taskId) => `/api/Task/${taskId}/Name`;

// 修改任务负责人
export const UpdateTaskLeaderApi = (taskId) => `/api/Task/${taskId}/PM`;
// 修改任务状态
export const UpdateTaskStatusApi = (taskId) => `/api/Task/${taskId}/Status`;
// 修改任务计划时间
export const UpdateTaskPlanTimeApi = (taskId) => `/api/Task/${taskId}/PlanTime`;
// 修改任务优先级
export const UpdateTaskPriorityApi = (taskId) => `/api/Task/${taskId}/PrecedenceLevel`;

// 添加任务标签
export const AddTaskTagApi = (taskId) => `/api/Task/${taskId}/Tag`;
// 删除任务标签
export const DeleteTaskTagApi = (taskId) => `/api/Task/${taskId}/Tag`;

// 关联任务审批
export const UpdateTaskProcessApi = (taskId) => `/api/Task/${taskId}/ProcessId`;

// 添加任务备注
export const UpdateTaskRemarksApi = (taskId) => `/api/Task/${taskId}/Remarks`;

// 获取任务交付物
export const GetTaskDeliverableApi = (taskId) => `/api/Deliverable/${taskId}`;
// 添加任务交付物
export const UpdateTaskDeliverableApi = (taskId) => `/api/Deliverable/${taskId}`;
// 关联前置任务
export const UpdateTaskPreTaskApi = (taskId) => `/api/Task/${taskId}/PreTaskId`;

// 任务发布
export const PublishTaskApi = (taskId) => `/api/Task/${taskId}/Begin`;
// 删除任务
export const DeleteTaskApi = (taskId) => `/api/Task/${taskId}`;

// 添加任务成员
export const UpdateTaskMembersApi = (taskId) => `/api/Task/${taskId}/Members`;

// 添加任务提醒
export const UpdateTasksRemindApi = (taskId) => `/api/Task/${taskId}/RemindMembers`;

// 上传交付物
export const UploadDeliverableApi = `/api/Deliverable/Submit`;
// 删除交付物
export const DeleteDeliverableApi = (deliverableId) => `/api/Deliverable/${deliverableId}`;

// 获取钉盘文件列表
export const GetDingDriveSpacesFilesApi = `/api/DingDisk/GetFiles`;

export const GetTaskToolkitFilesApi = `/api/ToolBox`;

export const AddTaskToolkitFilesApi = `/api/ToolBox`;

export const DeleteToolkitFileApi = (toolkitId) => `/api/ToolBox/${toolkitId}`;

export const AddProjectApi = `/api/Item`;

// 获取审批结构
export const GetProcessSchemaApi =  `/api/Process/Init`;
// 提交审批
export const CreateProcessApi = `/api/Process/Create`;

export const UploadFileApi = `${process.env.VUE_APP_API_URL}/api/${ApiVersion}/OssFile/Uploads`;

export const UpdateProjectCover = (projectId) => `/api/Item/${projectId}/Cover`;

export const UpdateProjectName = (projectId) => `/api/Item/${projectId}/Name`;

export const UpdateProjectType = (projectId) => `/api/Item/${projectId}/Type`;

export const UpdateProjectClassify = (projectId) => `/api/Item/${projectId}/Classify`;

export const UpdateProjectDescription = (projectId) => `/api/Item/${projectId}/Description`;

export const UpdateProjectTime = (projectId) => `/api/Item/${projectId}/Time`;

export const UpdateProjectFengkong = (projectId) => `/api/Item/${projectId}/FengKongEmployee`;

export const UpdateProjectZonghe = (projectId) => `/api/Item/${projectId}/ZongHeEmployee`;

export const UpdateProjectCaiwu = (projectId) => `/api/Item/${projectId}/CaiWuEmployee`;

/**
 * 删除项目组成员
 * @param {number} projectId 
 * @returns 
 */
export const DeleteProjectMemberApi = (projectId) => `/api/Item/${projectId}/Employee`;

export const CreateKpiScoreRecordApi = (projectId) => `/api/KPI/${projectId}/DailyRecord`;

export const GetKpiScoreRecordApi = (projectId) => `/api/KPI/${projectId}/DailyRecords`;


export const CreateKpiAssessmentApi = (projectId) => `/api/KPI/${projectId}/CreateaRecord`;

export const GetKpiAssessmentApi = (projectId) => `/api/KPI/${projectId}/Records`;

export const GetKpiMemberAssessmentApi = (kpiId) => `/api/KPI/${kpiId}`;

export const GetKpiMemberAssessmentDetailApi = (kpiId) => `/api/KPI/${kpiId}/Results`;

export const AddKpiScoreApi = (kpiItemId) => `/api/KPI/${kpiItemId}/Score`;

export const UpdateTaskRateApi = (taskId) => `/api/Task/${taskId}/Score`

export const UpdateProjectStatus = (projectId) => `/api/Item/${projectId}/Status`

// ====================template===================
/**
 * 获取模板列表
 */
export const GetTemplatesApi = '/api/Template/Milestones';

/**
 * 创建模板
 */
export const CreateTemplateApi = '/api/Template/Milestone';

/**
 * 获取模板详情
 * @param {int} templateId 模板Id
 * @returns 
 */
export const GetTemplateByIdApi = (templateId) => `/api/Template/${templateId}/Milestone`;

/**
 * 更新模板
 * @param {int} templateId 模板Id
 * @returns 
 */
export const UpdateTemplateByIdApi = (templateId) => `/api/Template/${templateId}/Milestone`;

/**
 * 获取模板任务列表
 * @param {int} templateId 模板Id
 * @returns 
 */
export const GetTemplateTasksApi =  `/api/Template/Tasks`;

/**
 * 创建模板任务
 */
export const CreateTemplateTaskApi = "/api/Template/Task";

/**
 * 获取模板任务详情
 * @param {int} templateTaskId 
 * @returns 
 */
export const GetTemplateTaskByIdApi = (templateTaskId) => `/api/Template/${templateTaskId}/Task`;

/**
 * 更新模板任务
 * @param {int} templateTaskId 
 * @returns 
 */
export const UpdateTemplateTaskByIdApi = (templateTaskId) => `/api/Template/${templateTaskId}/Task`;

/**
 * 获取导入模板列表
 */
export const GetImportTemplatesApi = '/api/Template';

/**
 * 模板导入
 */
export const UpdateImportTemplate = "/api/Template/Import";


//=====================
/**
 * 获取审批角色
 */
export const GetProcessRolesApi = "/api/Template/Process/Roles";
/**
 * 获取审批结束后动作
 */
export const GetProcessCompletedJobsApi = "/api/Template/Process/CallbackBusinessTypes";

/**
 * 获取项目经理列表
 */
export const GetProjectPMsApi = "/api/employee/getpms";

//============
/**
 * 获取分析报表
 */
export const GetDashboardAnalysisApi = "/api/Statistics";

/**
 * 获取部门列表
 */
export const GetDepartmentApi = "/api/Item/Department/Options";