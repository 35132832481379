<template>
  <a-row>
    <a-col flex="232px" class="logo">
      <img alt="盛元智本" src="./../assets/logo2.jpg" />
      <span style="color:rgba(0, 0, 0, 0)">{{config&&config.version}}</span>
    </a-col>
    <a-col flex="1" class="menu">
      <a-menu v-model:selectedKeys="selectedKeys" mode="horizontal" @click="onMemuClick"
        :class="{ 'menu-expand': !collapsed, 'menu-collapsed': collapsed }">
        <a-menu-item key="profile">
          <dashboard-outlined />
          <span>概览</span>
        </a-menu-item>
        <a-menu-item key="project">
          <project-outlined />
          <span>项目</span>
        </a-menu-item>
        <a-menu-item key="my-task-r">
          <bars-outlined />
          <span>我的任务</span>
        </a-menu-item>
        <a-menu-item key="toolkit">
          <folder-outlined />
          <span>工具箱</span>
        </a-menu-item>
        <a-menu-item key="template" v-if="isAdmin">
          <layout-outlined />
          <span>项目模板</span>
        </a-menu-item>
      </a-menu>
    </a-col>
  </a-row>
</template>
<script setup>
import { ref, computed } from 'vue';
import { useRoute, useRouter } from "vue-router";
import { useStore } from 'vuex';
import {
  DashboardOutlined,
  BarsOutlined,
  ProjectOutlined,
FolderOutlined,
  LayoutOutlined
} from '@ant-design/icons-vue';
import { GetterTypes as AccountGetterTypes } from '@/store/modules/account';
import config from "./../../package.json";
const route = useRoute();
const router = useRouter();
const store = useStore();

const collapsed = computed(() => store.state.collapsed);

const selectedKeys = ref([route.name]);

const isAdmin = computed(() => {
  return store.getters[AccountGetterTypes.IS_ADMIN()];
});

const onMemuClick = (e) => {
  if (e.key === 'toolkit') {
    window.location.href = 'dingtalk://dingtalkclient/page/yunpan';
  } else {
    router.push({
      name: e.key
    });
  }
};

const isProduction = process.env.NODE_ENV === 'production';

</script>

<style scoped lang="less">

.logo{
  width: 232px;
  height: 64px;
  text-align: center;
  img{
    height: 50px;
  }
}
.menu{
  // background-color: red;
}
</style>