import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate"
import { createLogger } from 'vuex'

import account from './modules/account';
import dingtalk from './modules/dingtalk';
import project from './modules/project';
import task from './modules/task';
import tag from './modules/tag';
import approval from './modules/approval';
import dynamic from './modules/dynamic';
import deliverable from './modules/deliverable';
import toolkit from './modules/toolkit';
import kpi from './modules/kpi';

export const MutationTypes = {
  SET_TOKEN: 'setToken',
}

export default createStore({
  state: {
    collapsed: false
  },
  mutations: {
    collapse: (state) => {
      state.collapsed = !state.collapsed;

    }
  },
  actions: {
    collapse: (context) => {
      context.commit('collapse');
    }
  },
  modules: {
    dingtalk, project, task, tag, approval, account, dynamic, deliverable, toolkit, kpi
  },

  plugins: process.env.NODE_ENV !== 'production' ?
    [
      createPersistedState({
        key: "syzb-oa",
        paths: ['account']
      }),
      createLogger()
    ] :
    [
      createPersistedState({
        key: "syzb-oa",
        paths: ['account']
      })
    ],
})

