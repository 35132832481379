import * as approvalService from '@/api/approval';
import { MutationTypes as TaskMutationTypes } from './task.js';

const namespace = 'approval';


const state = {
  all: [],
  schemas: []
};

export const GetterTypes = {
  GET_SCHEMA: (root = true) => `${root ? namespace + '/' : ''}get_schema`
}

const getters = {
  [GetterTypes.GET_SCHEMA(false)]: (state) => (projectId) => {
    return (
      state.schemas.find((schema) => schema.projectId === projectId) || {
        form: [],
        departments: [],
      }
    );
  },
};

export const MutationTypes = {
  UPDATE_CC_LIST: (root = true) => `${root ? namespace + '/' : ''}update_cc_list`,
  UPDATE_ATTACHMENTS: (root = true) => `${root ? namespace + '/' : ''}update_attachments`,
}

const mutations = {
  update(state, payload) { 
    state.all = payload;
  },
  update_schema(state, payload) { 
    const index = state.schemas.findIndex(
      (item) => item.projectId === payload.projectId
    );
    if (index > -1) {
      state.schemas[index] = payload;
    }else {
      state.schemas.push(payload);
    }
  },
  [MutationTypes.UPDATE_CC_LIST(false)](state, payload) { 
    const index = state.schemas.findIndex(item => item.taskId === payload.taskId)
    if (index > -1) {
      state.schemas[index].ccList = payload.ccList;
    }
  },
  [MutationTypes.UPDATE_ATTACHMENTS(false)](state, payload) { 
    const index = state.schemas.findIndex(item => item.taskId === payload.taskId)
    if (index > -1) {
      state.schemas[index].form[payload.index].props.value = payload.files || [];
    }
  }
};

export const ActionTypes = {
  LIST: (root = true) => `${root ? namespace + '/' : ''}list`,
  SCHEMA: (root = true) => `${root ? namespace + '/' : ''}schema`,
  CREATE: (root = true) => `${root ? namespace + '/' : ''}create`,
}

const actions = {
  async [ActionTypes.LIST(false)]({ commit }, payload) { 
    const response = await approvalService.GetApprovalList();
    const approvals = response.data.map(item => { 
      return {
        groupId: item.processId,
        groupName: item.processName,
        list: item.childs.map(a => { 
          return {
            processId: a.processId,
            processName: a.processName
          }
        }),
      }
    });
    commit('update', approvals);
  },
  async [ActionTypes.SCHEMA(false)]({ commit }, payload) { 
    const { taskId, projectId } = payload;
    const response = await approvalService.GetProcessSchema(
      Object.assign({}, payload, { itemId: projectId })
    );
    const data = response.data;
    data.ccList = (data.ccList || []).map(item => {
      return {
        employeeId: item.employeeId,
        name: item.employeeName,
      };
    });
    
    data.approvers = (data.approvers || []).map(item => {
      return {
        employeeId: item.employeeId,
        name: item.employeeName,
      };
    });

    data.form = (data.form || []).map(item => { 
      if (item.props.options) { 
        const options = item.props.options.map(optStr => { 
          return JSON.parse(optStr);
        })
        item.props = Object.assign({}, item.props, { options: options });
      }
      return item;
    });
    
    commit("update_schema", Object.assign({}, response.data, { projectId }));
  },
  async [ActionTypes.CREATE(false)]({ commit, dispatch }, payload) { 
    const response = await approvalService.CreateProcess(payload);
    commit(TaskMutationTypes.CREATE_APPROVAL(), payload, { root: true });

  }
};


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
