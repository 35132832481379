import requests from '@/utils/axios';
import * as api from './api';

export const AddProjectTask = async (data) => {
  return await requests.post(api.AddProjectTaskApi, data);
}

export const GetProjectTaskList = (id, params) => {
  // const { page, pageSize, name, createTime, type, leader, member, key } = params;
  const uri = api.ProjectTaskListApi.replace('{_id}', id);
  return requests.get(`${uri}?keywords=&pmEmployeeId=&startTime=&endTime=&tagIds=&status=`);
}

export const GetTaskDetail = (id) => { 
  return requests.get(api.TaskDetailApi.replace('{_id}', id));
}

export const PublishTask = (taskId) => { 
  return requests.put(api.PublishTaskApi(taskId));
}

export const UpdateTask = async (data) => {
  const { taskId } = data;
  let response;
  var keys = Object.keys(data);
  for (var i = 0; i < keys.length; i++) { 
    const key = keys[i];
    if (key !== 'taskId') {
      switch (key) {
        case 'leader':
          response = await requests.put(api.UpdateTaskLeaderApi(taskId), { pmEmployeeId: data[key].employeeId });
          break;
        case 'name':
          response = await requests.put(api.UpdateTaskNameApi(taskId), data);
          break;
        case 'status':
          response = await requests.put(api.UpdateTaskStatusApi(taskId), data);
          break;
        case 'planTime':
          response = await requests.put(api.UpdateTaskPlanTimeApi(taskId), { planStartTime: data['startTime'], planEndTime: data['endTime'] });
          break;
        case 'priority':
          response = await requests.put(api.UpdateTaskPriorityApi(taskId), { precedenceLevel: data[key] });
          break;
        case 'remarks':
          response = await requests.put(api.UpdateTaskRemarksApi(taskId), data);
          break;
        case 'processId':
          response = await requests.put(api.UpdateTaskProcessApi(taskId), data);
          break;
        case 'members':
          response = await requests.put(api.UpdateTaskMembersApi(taskId), { memberEmployeeIds: data[key].map(i => i.employeeId) });
          break;
        case 'remind':
          response = await requests.put(api.UpdateTasksRemindApi(taskId), { remindEmployeeIds: data[key].map(i => i.employeeId) });
          break;
        case 'preTask':
          response = await requests.put(api.UpdateTaskPreTaskApi(taskId), { preTaskId: data[key].taskId });
          break;
        case 'score':
          response = await requests.put(api.UpdateTaskRateApi(taskId), data);
          break;
        case 'tagId':
          {
            const operation = data['operation'];
            switch (operation) {
              case 'add_tag':
                response = await requests.put(api.AddTaskTagApi(taskId), { tagId: data[key] });
                break;
              case 'delete_tag':
                response = await requests.delete(api.DeleteTaskTagApi(taskId), { data: { tagId: data[key] } });
                break;
            }
          }
          break;

      }
      // return true;
    } else {
      // return false;
    }
  }
  // Object.keys(data).map(async key => { 

    
  // });

  return response;
}

export const GetMyTasks = (params) => { 
  return requests.get(`${api.GetMyTasksApi}?keywords=${params.keywords || ''}&status=${params.status || ''}&finishDateType=${params.finishDateType||''}`);
}

export const DeleteTask = (taskId) => { 
  return requests.delete(api.DeleteTaskApi(taskId));
}