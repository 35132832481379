import dayjs from 'dayjs'

const namespace = 'account';

const state =
  process.env.NODE_ENV !== "production"
    ? {
        token:
          "eyJhbGciOiJSUzI1NiIsImtpZCI6ImNkYTBjYTY5YTdlYTI1MDU2YjVmMjkxOWNiYmMxOGE1IiwidHlwIjoiSldUIn0.eyJuYmYiOjE2NzkyOTE4MTgsImV4cCI6MTY3OTM3ODIxOCwiaXNzIjoibnVsbCIsImF1ZCI6WyJudWxsL3Jlc291cmNlcyIsImNybSIsImRpbmd0YWxrIiwiZmlsZSIsImZpbmFuY2UiLCJociIsImludmVzdG9yY3JtIiwiaXRlbSIsInByb2Nlc3NlcyJdLCJjbGllbnRfaWQiOiJkaW5ndGFsayIsInN1YiI6IjUxZDA2MTVkLTc4OTctNGRjZS1iYjFkLThkMGM3MzMzYmYxYSIsImF1dGhfdGltZSI6MTY3OTI5MTgxNywiaWRwIjoibG9jYWwiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoi5bSU5rWp5YabIiwibmFtZSI6IuW0lOa1qeWGmyIsImRpbmd0YWxrX3VzZXJpZCI6Im1hbmFnZXIxNDI5IiwiZGVwYXJ0bWVudHMiOiI0MSw2OCw2OSw5MSIsImFsbGRlcGFydG1lbnRzIjoiNDEsNjgsNjksOTEiLCJpc1NlbmlvciI6IkZhbHNlIiwidW5pb25JZCI6Ilp0QWlTY0llelZ2eUNORWtCNEVPT0t3aUVpRSIsImlzU3VwZXJBZG1pbiI6IlRydWUiLCJkZXBhcnRtZW50VHlwZXMiOiIxLDIiLCJzY29wZSI6WyJvcGVuaWQiLCJwcm9maWxlIiwiY3JtIiwiZGluZ3RhbGsiLCJmaWxlIiwiZmluYW5jZSIsImhyIiwiaW52ZXN0b3Jjcm0iLCJpdGVtIiwicHJvY2Vzc2VzIl0sImFtciI6WyJkaW5ndGFsa19jb2RlIl19.mdjndfZ8Ya_AX0U7udyhkQMu57lNwWPGabuHp840YlIiDMJnMbNv2zNL_TeOcR911PNsOURhRctrvzc-eh-MZlMjqGvY1BZ2ONpCpD7gMb_ZbhRY1PnG09NQ8vDJ19XKWcUPOIDAuqjiwUiBH0x_oUZmgeJ7MEVV7YxRx5aYxFG54ifxLjiF73Sv0LNDBvDeiKyCkhuUiiFN3jANHjfL1pCdOri5lDSefcmAdkoC2-4AZ9M5QSo440mc572AA-z6-U-CBRvLSQYAmgT-iN8Y4vVth7PwuMg2fFOMSERA9MOhTvm26CMNzYK06BQuEDNbsq4Fc7ZcJqmXMPY93rYG4A",
        expires_in: "2022-11-10T00:00:00.000Z",
        token_type: "Bearer",
        isSuperAdmin: true,
        isSenior: false,
        employeeId: "manager1429",
        name: "崔浩军",
        // "employeeId": "febf78a95b8442b7a3054de84724edd3",
        // "name": "沈斌3506",
      }
    : {};

export const GetterTypes = {
  IS_ADMIN: (root = true) => `${root ? namespace + "/" : ""}is_admin`,
  IS_SENIOR: (root = true) => `${root ? namespace + "/" : ""}is_senior`,
  GET_EMPLOYEE_ID: (root = true) => `${root ? namespace + "/" : ""}get_employee_id`,
  GET_NAME: (root = true) => `${root ? namespace + "/" : ""}get_name`,
};

const getters = {
  get_token: (state) => `${state.token_type} ${state.token}`,
  is_authenticated: (state) => {
    return (
      state.token !== "" &&
      state.token !== undefined &&
      state.token !== null &&
      dayjs(state.expires_in) > dayjs()
    );
  },
  [GetterTypes.IS_ADMIN(false)]: (state) => {
    return state.isSuperAdmin || false;
  },
  [GetterTypes.IS_SENIOR(false)]: (state) => {
    return state.isSenior || false;
  },
  [GetterTypes.GET_EMPLOYEE_ID(false)]: (state) => {
    return state.employeeId || "";
  },
  [GetterTypes.GET_NAME(false)]: (state) => {
    return state.name;
  },
};


export const MutationTypes = {
  SET_TOKEN: (root = true) => `${root ? namespace + '/' : ''} set_token`,
  CLEAR_TOKEN: (root = true) => `${root ? namespace + '/' : ''} clear_token`,
}

const mutations = {
  [MutationTypes.SET_TOKEN(false)](state, payload) {
    const { access_token, expires_in, token_type, employeeId, name, isSuperAdmin, isSenior} = payload
    state.token = access_token;
    state.token_type = token_type;
    state.expires_in = dayjs().add(expires_in, 'second').format('YYYY-MM-DD HH:mm:ss');
    state.employeeId = employeeId;
    state.name = name;
    state.isSuperAdmin = isSuperAdmin;
    state.isSenior = isSenior;
  },
  [MutationTypes.CLEAR_TOKEN(false)](state) {
    state.token = '';
    state.token_type = '';
    state.expires_in = '';
  }
}

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
