<template>
  <a-layout>
    <a-layout-header class="header">
      <Header></Header>
    </a-layout-header>
    <a-layout-content class="main">
      <Suspense>
        <router-view />
      </Suspense>
      <div style="clear:both;" />
    </a-layout-content>
  </a-layout>
</template>
<script>
export default {
  name: 'Layout'
}
</script>
<script setup>
import Header from './Header.vue';
</script>
<style scoped lang="less">
@import '@/style/index.less';

.header {
  padding: 0;
  background-color: #fff;
  display: none;

  @media(min-width: @screen-md) {
    display: block;
  }
}

.main {
  padding: 20px;
  background-color: #f0f2f5; //rgb(238,238,238);
  min-height: calc(100vh - 64px);
  overflow: auto;

  @media(max-width: @screen-xs) {
    padding: 0px;
  }



}
</style>