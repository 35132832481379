/**
 * 角色判断
 * @param {Array} roles 
 * @param  {...any} rs 
 * @returns 
 */
export const inRole = (roles, ...rs) => {
  return rs.some(role => roles.includes(role))
};
export const RoleTypes = {
  Leader: "leader",
  FengKong: "fengkong",
  CaiWu: "caiwu",
  ZongHe: "zonghe",
  Member: "member",
  Admin: "admin",
  Oneself: "oneself",
  Senior: "senior",
  Creator: "creator",
  TaskCreator: "task_creator",
};
export default RoleTypes;