import * as tagService from '@/api/tag';
import { MutationTypes as TaskMutationTypes } from './task.js';


const namespace = 'tag';


const state = {
  all: {},
};

export const GetterTypes = {
  FIND_IN_PROJECT: (root = true) => `${root ? namespace + '/' : ''}find_in_project`,
}

const getters = {
  [GetterTypes.FIND_IN_PROJECT(false)]: (state) => (projectId) => {
    return state.all[projectId] || [];
  }
};

const mutations = {
  update_tags(state, payload) {
    Object.keys(payload).forEach(key => {
      state.all[key] = payload[key];
    });
  },
  delete_tag(state, payload) {
    const { projectId, tagId } = payload;
    state.all[projectId].splice(state.all[projectId].findIndex(item => item.tagId === tagId), 1);
  },
  add_tag(state, payload) {
    const { projectId, tagId } = payload;
    if (!state.all[projectId]) {
      state.all[projectId] = [];
    }
    state.all[projectId].push(payload);
  }
};


export const ActionTypes = {
  GET_PROJECT_TAGS: (with_namespaced = true) => `${with_namespaced ? namespace + '/' : ''}get_project_tags`,
  DELETE_TAG: (with_namespaced = true) => `${with_namespaced ? namespace + '/' : ''}delete_tag`,
  CREATE_TAG: (with_namespaced = true) => `${with_namespaced ? namespace + '/' : ''}create_tag`,
}

const actions = {
  async [ActionTypes.GET_PROJECT_TAGS(false)]({ commit }, payload) {
    const { projectId } = payload;
    const response = await tagService.GetProjectTags(projectId);
    const map = {}
    map[projectId] = response.data;
    commit('update_tags', map);
    return response.data;
  },
  async [ActionTypes.DELETE_TAG(false)]({ commit }, payload) {
    const { tagId } = payload;
    await tagService.DeleteTag(tagId);
    commit('delete_tag', payload);
    commit(TaskMutationTypes.DELETE_TAG(), payload, { root: true }) 
  },
  async [ActionTypes.CREATE_TAG(false)]({ commit }, payload) {
    const { projectId, name } = payload;
    const response = await tagService.AddTag({ name, itemId: projectId });
    const { tagId } = response.data;
    commit('add_tag', { projectId, name, tagId });
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};