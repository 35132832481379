import * as taskService from "@/api/task";
import dayjs from "dayjs";
import { ActionTypes as dynamicActionTypes } from "./dynamic";

const namespace = "task";

export const TaskType = {
  // 里程碑
  Milestone: 1,
  // 普通任务
  Task: 2,
};

export const TaskStatus = {
  // 待发布 0
  WaitPublish: 0,
  // 进行中 1
  Processing: 1,
  // 已完成 2
  Finished: 2,
};

const format = (item) => {
  let planTime = [];
  if (item.planStartTime && item.planEndTime) {
    planTime = [dayjs(item.planStartTime), dayjs(item.planEndTime)];
  }

  const data = Object.assign({}, item, {
    key: item.taskId,
    leader: {},
    members: (item.memberEmployees || []).map((i) => {
      if (typeof i === "string") {
        return { name: i };
      } else {
        return { name: i.employeeName, employeeId: i.employeeId };
      }
    }),
    creatorId: item.creatorEmployeeId,
    remind: (item.remindEmployees || []).map((i) => {
      return { name: i.employeeName, employeeId: i.employeeId };
    }),
    endTime: item.planEndTime || item.endTime,
    startTime: item.planStartTime || item.startTime,
    priority: item.precedenceLevel,
    progress: Math.floor((item.taskFinishCount / item.taskTotalCount) * 100),
    isPublish: item.isBegin || false,
    remarks: item.remarks || "",
    tags:
      item.taskTags ||
      (item.tags || []).map((t) => {
        return {
          name: t,
        };
      }),
    planTime,
    milestoneId: item.milestoneId || item.parentTaskId,
    projectName: item.itemName,
    projectId: item.projectId || item.itemId,
    preTask: format2(item.preTask),
  });

  if (item.pm) {
    data.leader = {
      name: item.pm.employeeName,
      employeeId: item.pm.employeeId,
    };
  }
  if (item.pmEmployee) {
    data.leader = {
      name: item.pmEmployee.employeeName,
      employeeId: item.pmEmployee.employeeId,
    };
    delete data.pmEmployee;
  }
  if (item.processId) {
    data.process = {
      processId: item.processId,
      processName: item.dingdingProcessName,
      instanceId: item.dingdingProcessInstanceId,
      status: item.dingdingProcessInstanceStatus,
    };
  }

  delete data.pm;
  delete data.remindEmployees;
  delete data.memberEmployees;
  delete data.isBegin;
  delete data.precedenceLevel;
  delete data.finishTime;
  delete data.planEndTime;
  delete data.planStartTime;
  delete data.taskTags;
  // delete data.parentTaskId;
  delete data.processId;
  delete data.dingdingProcessName;
  delete data.dingdingProcessInstanceId;
  delete data.dingdingProcessInstanceStatus;
  delete data.itemName;
  delete data.itemId;
  delete data.preTaskId;
  delete data.preTaskName;
  return data;
};

const format2 = (item) => { 
  if (!item) { 
    return item;
  }
  const keys = Object.keys(item);
  const data = {};
  keys.forEach(key => {
    let value = item[key];
    if (value) {
      switch (key) {
        case "planStartTime":
          data.startTime = value;
          break;
        case "planEndTime":
          data.endTime = value;
          break;
        case "pmEmployeeId":
          data.leader = {
            employeeId: value,
          };
          break;
        case "pmEmployee":
          data.leader = {
            employeeId: value.employeeId,
            name: value.employeeName,
          };
          break
        case "isBegin":
          data.isPublish = value;
          break;
        default:
          data[key] = item[key];
          break;
      }
    }
    
  });

  return data;
}

const state = {
  all: [],
  my: [],
};

export const GetterTypes = {
  FIND_IN_PROJECT: (root = true) =>
    `${root ? namespace + "/" : ""}find_in_project`,
  FIND_IN_PROJECT_BY_ID: (root = true) =>
    `${root ? namespace + "/" : ""}find_in_project_by_id`,
  FIND_MY_TASKS: (root = true) => `${root ? namespace + "/" : ""}find_my_tasks`,
  FIND_PRE_TASKS: (root = true) =>
    `${root ? namespace + "/" : ""}find_pre_tasks`,
  // FIND_GANTT_IN_FIND_IN_PROJECT: (root = true) => `${root ? namespace + '/' : ''}find_gantt_in_find_in_project`,
};

const getters = {
  [GetterTypes.FIND_IN_PROJECT(false)]: (state) => (projectId) => {
    // const milestones =
    //   state.all.filter(
    //     (task) =>
    //       task.projectId === projectId && task.taskType === TaskType.Milestone
    //   ) || [];
    // const list = [];
    // // console.log('get milestones', milestones)
    // milestones.forEach((milestone) => {
    //   list.push(milestone);
    //   let children =
    //     state.all.filter(
    //       (task) =>
    //         task.parentTaskId === milestone.taskId &&
    //         task.taskType === TaskType.Task
    //     ) || [];
    //   // console.log('get children', children)
    //   list.push(...children);
    // });
    return state.all.filter(task => task.projectId === projectId).sort((a, b) => { 
      let aSortId =
        a.taskType === TaskType.Milestone ? a.taskId : a.parentTaskId;
      let bSortId =
        b.taskType === TaskType.Milestone ? b.taskId : b.parentTaskId;
      
      return aSortId > bSortId;
    });
    // return list;
  },
  [GetterTypes.FIND_IN_PROJECT_BY_ID(false)]:
    (state) => (projectId, taskId) => {
      return (
        state.all.find(
          (task) => task.projectId === projectId && task.taskId === taskId
        ) || {}
      );
    },
  [GetterTypes.FIND_MY_TASKS(false)]: (state) => () => {
    return state.my;
  },
  [GetterTypes.FIND_PRE_TASKS(false)]:
    (state) => (projectId, keywords, currentTaskId) => {
      const list =
        state.all.filter(
          (task) =>
            task.projectId === projectId &&
            task.name.indexOf(keywords) !== -1 &&
            task.taskId !== currentTaskId
        ) || [];
      return list.map((item) => {
        return Object.assign({}, item, { value: item.name });
      });
    },
};

export const MutationTypes = {
  UPDATE_LIST: (root = true) => `${root ? namespace + "/" : ""}update_list`,
  UPDATE: (root = true) => `${root ? namespace + "/" : ""}update`,
  UPDATE_MY_LIST: (root = true) =>
    `${root ? namespace + "/" : ""}update_my_list`,
  DELETE: (root = true) => `${root ? namespace + "/" : ""}delete`,
  DELETE_TAG: (root = true) => `${root ? namespace + "/" : ""}delete_tag`,
  CREATE_APPROVAL: (root = true) =>
    `${root ? namespace + "/" : ""}create_approval`,
};

const mutations = {
  [MutationTypes.UPDATE_LIST(false)](state, payload) {
    payload.forEach((item) => {
      const index = state.all.findIndex((i) => i.taskId === item.taskId);
      if (index > -1) {
        if (item._detail === true) {
          state.all.splice(index, 1, item);
        }
      } else {
        state.all.push(item);
      }
    });
  },
  [MutationTypes.UPDATE(false)](state, payload) {
    const { taskId } = payload;
    const index = state.all.findIndex((i) => i.taskId === taskId);
    if (index !== -1) {
      const item = state.all[index];
      delete payload.tagId;
      delete payload.operation;

      const data = Object.assign({}, item, payload);
      state.all[index] = data;
    }
  },
  [MutationTypes.UPDATE_MY_LIST(false)](state, payload) {
    state.my = payload;
  },
  [MutationTypes.DELETE(false)](state, payload) {
    const { taskId } = payload;
    const index = state.all.findIndex((i) => i.taskId === taskId);
    if (index > -1) {
      state.all.splice(index, 1);
    }
  },
  [MutationTypes.DELETE_TAG(false)](state, payload) {
    const { projectId, tagId } = payload;
    state.all = state.all.map((task) => {
      if (task.projectId === projectId) {
        if (task.tags && task.tags.length > 0) {
          const tags = task.tags;
          const index = tags.findIndex((item) => item.tagId === tagId);
          if (index !== -1) {
            tags.splice(index, 1);
          }
        }
      }
      return task;
    });
  },
  [MutationTypes.CREATE_APPROVAL(false)](state, payload) {
    const { taskId } = payload;
    state.all = state.all.map((task) => {
      if (task.taskId === taskId) {
        // data.process = {
        //   processId: item.processId,
        //   processName: item.dingdingProcessName,
        //   instanceId: item.dingdingProcessInstanceId,
        //   status: item.dingdingProcessInstanceStatus
        // };
        if (task.process) {
          task.process.status = "start";
        }
      }
      return task;
    });
  },
};

export const ActionTypes = {
  ADD_TASK: (root = true) => `${root ? namespace + "/" : ""}add_task`,
  ADD_MILESTONE: (root = true) => `${root ? namespace + "/" : ""}add_milestone`,
  LIST: (root = true) => `${root ? namespace + "/" : ""}list`,
  DETAIL: (root = true) => `${root ? namespace + "/" : ""}detail`,
  UPDATE: (root = true) => `${root ? namespace + "/" : ""}update`,
  PUBLISH: (root = true) => `${root ? namespace + "/" : ""}publish`,
  MY: (root = true) => `${root ? namespace + "/" : ""}my`,
  DELETE: (root = true) => `${root ? namespace + "/" : ""}delete`,
};

const actions = {
  async [ActionTypes.LIST(false)]({ commit }, payload) {
    const { projectId } = payload;
    const response = await taskService.GetProjectTaskList(projectId);
    if (Array.isArray(response.data)) {
      const list = [];
      response.data.forEach((item) => {
        // if (item.childs && item.childs.length > 0) {
        //   console.log('list', item)
        //   item.childs.forEach(child => {
        //     delete child.childs
        //     list.push(format({
        //       ...child,
        //       projectId
        //     }));
        //   });
        //   delete item.childs
        //   delete item.milestoneName
        // }

        list.push(format(Object.assign({}, item, { projectId })));
      });
      commit(MutationTypes.UPDATE_LIST(false), list);
    }
  },
  async [ActionTypes.DETAIL(false)]({ commit }, payload) {
    const { taskId, projectId } = payload;
    const response = await taskService.GetTaskDetail(taskId);
    const { data } = response;
    let templateDefaultValue = null;
    if (data.templateDefaultValue) {
      templateDefaultValue = format2(data.templateDefaultValue);
    }
    const task = format(
      Object.assign({}, data, {
        projectId,
        templateDefaultValue,
        _detail: true,
      })
    );
    delete task.itemId;

    commit(MutationTypes.UPDATE_LIST(false), [task]);
  },
  async [ActionTypes.ADD_TASK(false)]({ commit }, payload) {
    const { projectId, milestoneId, name } = payload;
    const response = await taskService.AddProjectTask({
      itemId: projectId,
      parentTaskId: milestoneId,
      name,
    });
    const { taskId } = response.data;

    const task = Object.assign({}, payload, {
      taskId,
      // taskType: TaskType.Task,
      status: TaskStatus.WaitPublish,
      parentTaskId: milestoneId,
      members: [],
    });
    commit(MutationTypes.UPDATE_LIST(false), [task]);
  },
  async [ActionTypes.ADD_MILESTONE(false)]({ commit }, payload) {
    const { projectId, name } = payload;
    const response = await taskService.AddProjectTask({
      itemId: projectId,
      name,
    });
    const { taskId } = response.data;
    // const task = format({
    //   ...payload,
    //   taskId,
    //   taskType: TaskType.Milestone
    // });
    const task = Object.assign({}, payload, {
      taskId,
      taskType: TaskType.Milestone,
      status: TaskStatus.WaitPublish,
      members: [],
    });
    commit(MutationTypes.UPDATE_LIST(false), [task]);
  },
  async [ActionTypes.UPDATE(false)]({ commit, dispatch }, payload) {
    const response = await taskService.UpdateTask(payload);
    commit(MutationTypes.UPDATE(false), payload);
    dispatch(dynamicActionTypes.LIST(), payload, { root: true });
  },
  async [ActionTypes.PUBLISH(false)]({ commit, dispatch }, payload) {
    const { taskId } = payload;
    await taskService.PublishTask(taskId);
    commit(MutationTypes.UPDATE(false), {
      taskId,
      isPublish: true,
      status: TaskStatus.Processing,
    });
    dispatch(dynamicActionTypes.LIST(), payload, { root: true });
  },
  async [ActionTypes.MY(false)]({ commit }, payload) {
    const response = await taskService.GetMyTasks(payload);
    const { data } = response;
    const list = data.map((item) => format(item));
    commit(MutationTypes.UPDATE_MY_LIST(false), list);
  },
  async [ActionTypes.DELETE(false)]({ commit, dispatch }, payload) {
    const { taskId } = payload;
    const response = await taskService.DeleteTask(taskId);
    commit(MutationTypes.DELETE(false), payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
