import store from "@/store"
import { computed } from 'vue';
import { GetterTypes as AccountGetterTypes } from '@/store/modules/account';
import { GetterTypes as ProjectGetterTypes } from '@/store/modules/project';

export const getToken = () => {
  return store.getters['account/get_token'];
}

// 判断是否授权或过期
export const isAuthenticated = () => {
  return store.getters['account/is_authenticated'];
}