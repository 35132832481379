import axios from 'axios';
import { message } from 'ant-design-vue';
import { getToken } from '@/utils/auth';

import { ActionTypes as dingtalkActionTypes } from '@/store/modules/dingtalk';

// 封装axios到队列
const queue = [];
const loadingKey = 'loading';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // timeout: 3000,
  showError: true,
  hideLoading: false,
});
export const setupInterceptors = (app, store, router) => {
  instance.interceptors.request.use(config => {
    if (!config.hideLoading) {
      if (queue.length === 0) {
        message.loading({ content: '加载中...', duration: 0, key: loadingKey });
      }
      queue.push(config);
    }
    const token = getToken();
    if (token) {
      config.headers.Authorization = token;
    }
  
    return config;
  });

  instance.interceptors.response.use(
    response => {
      if (!response.config.hideLoading) {
        queue.shift();
        if (queue.length === 0) {
          message.destroy();
        }
      }
      return response;
    },
    async error => {
      if (error && error.response) {
        let onClose = () => { };
        //TODO 400 根据后端返回的错误码进行处理
        //TODO 401, 403, 需要弹出登录框
        switch (error.response.status) {
          case 400:
            // console.log(error);
            
            error.message = error.response.data.error||'请求错误(400)';
            break;
          case 401:
            { 
              error.message = '未授权，请重新登录(401)';
              const corpId = process.env.VUE_APP_DINGTALK_CORPID;
              try {
                await store.dispatch(dingtalkActionTypes.LOGIN(), { corpId });
              } catch (error) {
                // console.log("====",error);
              }
              
              return instance.request(error.config);
            }
          case 403: error.message = '拒绝访问(403)'; break;
          case 404: error.message = '请求出错(404)'; break;
          case 408: error.message = '请求超时(408)'; break;
          case 500: error.message = '服务器错误(500)'; break;
          case 501: error.message = '服务未实现(501)'; break;
          case 502: error.message = '网络错误(502)'; break;
          case 503: error.message = '服务不可用(503)'; break;
          case 504: error.message = '网络超时(504)'; break;
          case 505: error.message = 'HTTP版本不受支持(505)'; break;
          default: error.message = `连接出错(${error.response.status})!`;
        }
        // 判断config是否需要提示错误信息
        if (error.config.showError && error.message) {
          message.error(error.message, 3, onClose);
        }

      }
      if (!error.config.hideLoading) {
        queue.shift();
        if (queue.length === 0) {
          message.destroy(loadingKey);
        }
      }
      return Promise.reject(error);
    }
  );
}
export default instance;