import requests from '@/utils/axios';
import * as api from './api';

export const GetTaskToolkitFiles = (query) => { 
  return requests.get(api.GetTaskToolkitFilesApi, {
    params: query,
  });
}

export const AddTaskToolkitFiles = (data) => { 
  return requests.post(api.AddTaskToolkitFilesApi, data);
}

export const DeleteToolkitFile = (toolkitFileId) => { 
  return requests.delete(api.DeleteToolkitFileApi(toolkitFileId));
}