import Layout from '@/layouts/Layout.vue';
import {
  BarsOutlined,
} from '@ant-design/icons-vue';

export default [
  {
    path: '/task',
    name: 'task_base',
    redirect: '/task/my',
    component: Layout,
    meta: { title: '我的任务', icon: <BarsOutlined /> },
    children: [
      {
        name: 'my-task',
        path: 'my/:key',
        component: () => import(/* webpackChunkName: "task" */ '@/views/task/MyView.vue'),
        meta: { title: '盛元智本OA项目管理系统-我的任务', },
      },
      {
        name: 'my-task-r',
        path: 'my',
        redirect: to => {
          return { name: 'my-task', params: { key: 'all' } }
        }
      },
    ]
  },
]