import Layout from '@/layouts/Layout.vue';
import { LayoutOutlined } from '@ant-design/icons-vue';
export default [
  {
    path: "/template",
    name: "template_base",
    // redirect: '/template/',
    component: Layout,
    meta: { title: "项目模板", icon: <LayoutOutlined />, roles: ["admin"] },
    children: [
      {
        name: "template",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "template" */ "@/views/template/list/IndexView.vue"
          ),
        meta: { title: "模板列表" },
      },
      {
        name: "template_edit",
        path: ":templateId/edit",
        component: () =>
          import(
            /* webpackChunkName: "template" */ "@/views/template/edit/EditView.vue"
          ),
        meta: { title: "模板编辑" },
      },
      {
        name: "template_tasks",
        path: ":templateId/tasks",
        component: () =>
          import(
            /* webpackChunkName: "template" */ "@/views/template/tasks/IndexView.vue"
          ),
        meta: { title: "模板管理" },
      },
      {
        name: "template_task_edit",
        path: ":templateId/tasks/:taskId/edit",
        component: () =>
          import(
            /* webpackChunkName: "template" */ "@/views/template/tasks/edit/EditView.vue"
          ),
        meta: { title: "模板任务编辑" },
      }
    ],
  },
];