import * as toolkitService from '@/api/toolkit';
import { ActionTypes as dynamicActionTypes } from './dynamic'

const namespace = 'toolkit';

const state = {
  all: []
};

export const GetterTypes = {
  FIND_BY_TASKID: (root = true) => `${root ? namespace + '/' : ''} find_by_taskid`,
  FIND_BY_PROJECTID: (root = true) => `${root ? namespace + '/' : ''} find_by_projectid`,
}

const getters = {
  [GetterTypes.FIND_BY_TASKID(false)]: (state) => (taskId) => {
    return state.all.filter(item => item.taskId === taskId);
  },
  [GetterTypes.FIND_BY_PROJECTID(false)]: (state) => (projectId) => {
    return state.all.filter(item => item.projectId === projectId);
  }
};


export const MutationTypes = {
}

const mutations = {
  update(state, payload) {
    state.all = payload;
  },
  delete(state, payload) {
    const { toolkitFileId } = payload;
    const index = state.all.findIndex(i => i.toolkitFileId === toolkitFileId);
    if (index !== -1) {
      state.all.splice(index, 1);
    }
  }
}


export const ActionTypes = {
  GET_TOOLKIT_FILES: (root = true) => `${root ? namespace + '/' : ''}get_toolkit_files`,
  ADD_TOOLKIT_FILES: (root = true) => `${root ? namespace + '/' : ''}add_toolkit_files`,
  DELETE_TOOLKIT_FILE: (root = true) => `${root ? namespace + '/' : ''}delete_toolkit_file`,
}

const actions = {
  async [ActionTypes.GET_TOOLKIT_FILES(false)]({ commit }, payload) {
    const { projectId, taskId } = payload;
    const response = await toolkitService.GetTaskToolkitFiles(
      Object.assign({}, payload, { itemId: projectId })
    );
    const data = response.data.map(item => {
      return {
        toolkitFileId: item.itemFileToolBoxId,
        spaceId: item.dingTalkFile.spaceId,
        fileId: item.dingTalkFile.fileId,
        fileName: item.dingTalkFile.title,
        fileSize: item.dingTalkFile.size,
        fileType: item.dingTalkFile.fileType,
        updateTime: item.updateTime,
        creator: item.employeeName,
        source: item.source,
        fileBusinessType: item.fileBusinessType,
        taskId,
        projectId
      }
    })
    commit('update', data);
  },
  async [ActionTypes.ADD_TOOLKIT_FILES(false)]({ commit, dispatch }, payload) {
    const { projectId, taskId, files } = payload;
    const response = await toolkitService.AddTaskToolkitFiles(payload);
    const data = payload.files.map((item, index) => {
      return Object.assign({}, item, {
        toolkitFileId: response.data.itemDeliverableIds[index],
        projectId,
        taskId
      });
    });
    commit('update', data);
    dispatch(dynamicActionTypes.LIST(), payload, { root: true });
  },
  async [ActionTypes.DELETE_TOOLKIT_FILE(false)]({ commit, dispatch }, payload) {
    const { toolkitFileId } = payload;
    await toolkitService.DeleteToolkitFile(toolkitFileId);
    commit('delete', { toolkitFileId });
    dispatch(dynamicActionTypes.LIST(), payload, { root: true });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
