import * as deliverableService from '@/api/deliverable';
import { ActionTypes as dynamicActionTypes } from './dynamic'

const namespace = 'deliverable';


const state = {
  all: {},
};

export const GetterTypes = {
  FIND_IN_TASK: (root = true) => `${root ? namespace + '/' : ''}find_in_task`,
}
const getters = {
  [GetterTypes.FIND_IN_TASK(false)]: (state) => (taskId) => {
    return state.all[taskId] || [];
  }
};

const mutations = {
  update(state, payload) {
    Object.keys(payload).forEach(key => {
      state.all[key] = payload[key];
    });
  },
  upload(state, payload) {
    const { taskId, deliverableId, files } = payload;
    const deliverables = state.all[taskId] || [];
    deliverables.map(item => {
      if (item.deliverableId === deliverableId) {
        if (!item.files) { 
          item.files = [];
        }
        item.files.push(...files);
      }

      return item;
    })
  },
  delete(state, payload) {
    const { taskId, deliverableId, file } = payload;
    const deliverables = state.all[taskId] || [];
    deliverables.map(item => {
      if (item.deliverableId === deliverableId) {
        const index = item.files.findIndex(fileItem => fileItem.fileId === file.fileId);
        item.files.splice(index, 1);
      }

      return item;
    }).filter(item => item.files.length > 0);
  }
};

export const ActionTypes = {
  TASK: (root = true) => `${root ? namespace + '/' : ''}task`,
  UPDATE: (root = true) => `${root ? namespace + '/' : ''}update`,
  UPLOAD: (root = true) => `${root ? namespace + '/' : ''}upload`,
  DELETE_FILE: (root = true) => `${root ? namespace + '/' : ''}delete_file`,
}

const actions = {
  [ActionTypes.TASK(false)]: async ({ commit }, payload) => {
    const { taskId } = payload;
    const map = {};
    const response = await deliverableService.GetTaskDeliverableList(taskId);
    map[taskId] = response.data.map(item => {
      return Object.assign({}, item, {
        deliverableId: item.taskDeliverableDemandId,
        files: item.deliverables.map(file => {
          return Object.assign({}, file, {
            deliverableFileId: file.itemDeliverableId
          });
        })
      });
    });

    commit('update', map);
  },
  [ActionTypes.UPDATE(false)]: async ({ commit, dispatch }, payload) => {
    const { taskId, deliverables } = payload;
    const map = {};
    let data = deliverables.map(item => {
      const d = Object.assign({}, item, {
        taskDeliverableDemandId: item.deliverableId
      });
      delete d.key;
      return d;
    });
    map[taskId] = data;
    const response = await deliverableService.UpdateTaskDeliverable({
      taskId,
      deliverableDemands: data
    });
    map[taskId] = response.data.taskDeliverableDemandIds.map((id, i) => {
      return Object.assign({}, data[i], { taskDeliverableDemandId: id, deliverableId: id });
    });
    commit('update', map);
    dispatch(dynamicActionTypes.LIST(), payload, { root: true });
  },
  [ActionTypes.UPLOAD(false)]: async ({ commit, dispatch }, payload) => {
    const response = await deliverableService.UploadDeliverable(payload);
    const { itemDeliverableIds } = response.data;
    const files = payload.files.map((item, index) => { 
      return Object.assign({}, item, { deliverableFileId: itemDeliverableIds[index] });
    })
    const data = Object.assign({}, payload, { files });
    commit("upload", data);
    dispatch(dynamicActionTypes.LIST(), payload, { root: true });
  },
  [ActionTypes.DELETE_FILE(false)]: async ({ commit, dispatch }, payload) => {
    const { file: { deliverableFileId } } = payload;
    await deliverableService.DeleteDeliverableFile(deliverableFileId);
    commit('delete', payload);
    dispatch(dynamicActionTypes.LIST(), payload, { root: true });
  }
};


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
