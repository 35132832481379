import Layout from '@/layouts/Layout.vue';
import {
  DashboardOutlined,
  FolderOutlined
} from '@ant-design/icons-vue';

export default [
  {
    path: "/",
    name: "main",
    redirect: "/dashboard/profile",
    component: Layout,
    meta: {
      title: "概览",
      icon: <DashboardOutlined />,
      expanded: true,
    },
    children: [
      {
        path: "dashboard/profile",
        name: "profile",
        component: () => import("@/views/dashboard/HomeView.vue"),
        meta: { title: "仪表盘" },
      },
    ],
  },
  {
    path: "/toolkit",
    name: "toolkit",
    redirect: (to) => {
      window.location.href = "dingtalk://dingtalkclient/page/yunpan";
    },
    meta: {
      title: "工具箱",
      icon: <FolderOutlined />,
      expanded: true,
    },
  },
];
